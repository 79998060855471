import React, {useEffect, useState} from "react";
import ReactWordcloud from 'react-wordcloud';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import axios from "axios";
import {Box} from "@mui/material";
import {API_URL} from "../config";

export default function WordCloud(){
    const [parole, setParole] = useState([])
    const [result, setResult] = useState({})

    const options = {
        colors: ['#f1ebe9'],
        enableTooltip: false,
        deterministic: true,
        fontFamily: "roboto",
        fontSizes: [30, 100],
        fontStyle: "normal",
        fontWeight: "bold",
        padding: 2,
        rotations: 0,
        rotationAngles: [0, -90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
    };

    useEffect(() => {
        getData()
        window.clearInterval()
        let intervalId = window.setInterval(getData, 2000);
        return ()=> {
            window.clearInterval(intervalId)
        }
    }, [])

    function getData(){
        /*axios.get('https://api.secondstage.app/answer/roiYaXmbqyelGJzHQCoa/8IYoLWondJCdbedDmpSd').then(r => {
            setResult(r)
        })*/
        axios.get(`${API_URL}/wordcloud`).then(r => {
            setResult(r)
        })
    }

    useEffect(() => {
        Array.isArray(result.data) && setParole(result.data.slice(0,80))
    }, [result])
    return(
        <Box sx={{height:'100vh'}}>
            <ReactWordcloud words={parole} options={options}/>
        </Box>
    )
}
