import React from "react";
import {Box, Container} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {Questions} from "../components/Questions";
import {RenderStep} from "../components/RenderStep";

export const Home = () => {
    const [settings, loading, error] = useDocument(firestore.doc('config/settings'),{})
    const [nome, setNome] = React.useState('')
    const [domanda, setDomanda] = React.useState('')
    const [collega, setCollega] = React.useState('')

    const handleSetNome = (e) => {
        setNome(e.target.value)
    }

    const handleSetDomanda = (e) => {
        setDomanda(e.target.value)
    }

    const handleSetCollega = (e) => {
        setCollega(e.target.value)
    }

    return (
        <Container maxWidth={'sm'} sx={{py:4}}>
            <Box sx={{width:'10rem', margin:'auto', py:8}}>
                <img style={{width:'100%'}} src={require('../assets/logo-plesh.png')}/>
            </Box>
            {(!loading && !error) &&
                settings.data().domanda ?
                    <Questions handleSetNome={handleSetNome} handleSetDomanda={handleSetDomanda} handleSetCollega={handleSetCollega} nome={nome} domanda={domanda} collega={collega}/>
                    :
                    <RenderStep step={2}/>
            }
        </Container>
    )
}
