import './App.css';
import {CssBaseline} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import WordCloud from "./pages/WordCloud";

function App() {
  return (
    <div>
      <CssBaseline/>
      <Routes>
        <Route path={'/'} element={<Home/>}/>
        <Route path={'/wordcloud'} element={<WordCloud/>}/>
      </Routes>
    </div>
  );
}

export default App;
