import {createTheme, responsiveFontSizes} from "@mui/material";

export const theme = responsiveFontSizes(createTheme({
    palette:{
        mode:'dark',
        primary: {
            main:'#e6fc69'
        },
        background:{
            default:'#1D1D29'
        }
    }
}))
