import React, {useState} from "react";
import {Box} from "@mui/material";
import {RenderStep} from "./RenderStep";

export const Questions = ({handleSetNome, handleSetDomanda, nome, handleSetCollega, domanda, collega}) => {
    const [step, setStep] = useState(0)

    const handleNextStep = () => {
        if(step === 1) {
            setStep(step + 2)
        } else {
            setStep(step + 1)
        }
    }

    const handlePrevStep = () => {
        if(step === 3) {
            setStep(step - 2)
        } else {
            setStep(step - 1)
        }
    }

    return (
        <Box>
            <RenderStep step={step} handleNextStep={handleNextStep} handleSetNome={handleSetNome} handleSetDomanda={handleSetDomanda} handleSetCollega={handleSetCollega} nome={nome} domanda={domanda} collega={collega}/>
        </Box>
    )
}
