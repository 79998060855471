import React, {useState} from "react";
import {Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import axios from "axios";
import {Delete} from "@mui/icons-material";
import {API_URL} from "../config";
import {motion, useMotionValue} from "framer-motion";
import SuccessCheck from "./SuccessCheck";

export const SendPhoto = () => {
    const [foto, setFoto] = useState(null)
    const [url, setUrl] = useState('')
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const progress = useMotionValue(90)

    const handleCompileFoto = (e) => {
        const [f] = e.target.files
        setFoto(f)
        setUrl(URL.createObjectURL(f))
    }

    const sendPhoto = async () => {
        setSending(true)
        const formData = new FormData();
        formData.append('file', foto);
        await axios({
            url: `${API_URL}/upload`,
            method: 'POST',
            data: formData,
        }).then(() => {
            setFoto(null)
            setUrl('')
            setSuccess(true)
        })
            .catch(() => {
            })
        setSending(false)
    }
    return (
        <form style={{padding: '1rem 0'}}>
            {success ?
                <Stack alignItems={"center"} style={{width: '100%'}} sx={{mt: 1}} justifyContent={'center'}>
                    <div style={{width: '60%', textAlign: 'center'}}>
                        <motion.div
                            initial={{x: 0}}
                            animate={{x: 100}}
                            style={{x: progress}}
                            transition={{duration: 0.8}}
                        />
                        <SuccessCheck progress={progress}/>
                    </div>
                </Stack>
                :
                <Stack direction={"column"} spacing={4}>
                    <Box sx={{
                        width: '100%',
                        height: '16rem',
                        fontWeight: '600',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#ffffff15',
                        borderRadius: '1rem',
                        border: '2px dashed #fff',
                        padding: '1rem'
                    }} component="label">
                        {foto === null ?
                            <Box>
                                <Typography variant={'h5'}>Scegli una foto</Typography>
                                <input type="file" hidden onChange={handleCompileFoto}/>
                            </Box> :
                            <Box sx={{width: '100%', height: '100%'}}>
                                <Box sx={{width: '100%', height: '80%', margin: 'auto'}}>
                                    <img style={{width: '100%', height: '100%', objectFit: 'contain'}} src={url}/>
                                </Box>
                                <Box sx={{textAlign: 'center', pt: 1}}>
                                    <Button startIcon={<Delete/>} onClick={() => {
                                        setTimeout(() => {
                                            setFoto(null)
                                            setUrl('')
                                        }, 100)
                                    }}>
                                        elimina
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Box sx={{textAlign: 'center'}}>
                        {sending ? <CircularProgress/> : <Button onClick={sendPhoto} disabled={foto === null}
                                                                 variant={"contained"}>
                            invia
                        </Button>}
                    </Box>
                </Stack>
            }
        </form>
    )
}
