import React from "react";
import {Box, Button, CircularProgress, Stack, TextField, Typography} from "@mui/material";
import axios from "axios";
import {SendPhoto} from "./SendPhoto";
import SuccessCheck from "./SuccessCheck";
import {motion, useMotionValue} from "framer-motion";
import {API_URL} from "../config";

export const RenderStep = ({
                               step,
                               handleNextStep,
                               handleSetDomanda,
                               handleSetNome,
                               nome,
                               handleSetCollega,
                               collega,
                               domanda
                           }) => {
    const [sending, setSending] = React.useState(false)
    let progress = useMotionValue(90)
    const RenderText = () => {
        switch (step) {
            case 0:
            case 7:
                return 'Qual è un’idea creativa che avete avuto e che non è stata possibile realizzare a causa di un limite tecnologico?'
            case 1:
                return 'Prima però dicci il tuo nome:'
            case 2:
                return 'Mandaci un selfie'
            case 3:
                return 'Chi è il tuo/la tua collega preferit*?'
            case 4:
                return 'Ora puoi finalmente rispondere'
            case 5:
                return 'Clicca ancora avanti '
            case 6:
                return 'Ok, può bastare'
            case 8:
                return 'Grazie per aver partecipato!'
            default:
                return ''
        }
    }

    const ButtonText = () => {
        switch (step) {
            case 0:
                return 'RISPONDI'
            case 1:
                return 'invia'
            case 2:
                return 'invia'
            case 3:
                return 'invia'
            case 4:
                return 'AVANTI'
            case 5:
                return 'AVANTI'
            case 6:
                return 'BASTA PER FAVORE'
            case 7:
                return 'invia'
            default:
                return ''
        }
    }

    const sendQuestion = async () => {
        setSending(true)
        await axios.post('https://api-tool-press-yn26wrnl4q-ey.a.run.app/S7Bo9qzTHcksm5Ih6cf5/question', {Nome:nome, domanda})
        setSending(false)
    }

    const sendCollega = async () => {
        setSending(true)
        await axios.post(`${API_URL}/wordcloud`, {user:'user', answer:collega})
            .then(res => {handleNextStep()})
        setSending(false)
    }

    return (
        <Stack spacing={2}>
            <Typography textAlign={'center'} variant={'h6'}>
                <RenderText/>
            </Typography>
            {!([0, 4, 5, 6].includes(step)) &&
                <Box>
                    {(() => {
                        switch (step) {
                            case 1:
                                return <TextField fullWidth onChange={handleSetNome} value={nome} label={'Nome'}/>
                            case 2:
                                return <SendPhoto handleNextStep={handleNextStep}/>
                            case 3:
                                return <TextField fullWidth label={'Collega'} onChange={handleSetCollega} value={collega}/>
                            case 7:
                                return <TextField fullWidth onChange={handleSetDomanda} label={'Rispondi qui'} multiline
                                                  value={domanda}
                                                  minRows={3}/>
                            case 8:
                                return <Stack alignItems={"center"} style={{width:'100%'}} sx={{mt:1}} justifyContent={'center'}>
                                    <div style={{width:'60%', textAlign:'center'}}>
                                        <motion.div
                                            initial={{x: 0}}
                                            animate={{x: 100}}
                                            style={{x: progress}}
                                            transition={{duration: 0.8}}
                                        />
                                        <SuccessCheck progress={progress}/>
                                    </div>
                                </Stack>
                            default:
                                return ''
                        }
                    })()}
                </Box>
            }
            {(step !== 8 && step!==2) && <Box sx={{textAlign: 'center'}}>
                {sending ? <CircularProgress/> : <Button
                    disabled={
                        (step === 1 && nome === '') ||
                        (step === 3 && collega === '') ||
                        (step === 7 && domanda === '')
                    }
                    variant={'contained'}
                    onClick={
                        step === 3 ?
                            () => {
                                sendCollega()
                            }
                            :
                            step === 7 ?
                                () => {
                                    sendQuestion()
                                    handleNextStep()
                                }
                                :
                                handleNextStep
                    }
                >
                    <ButtonText/>
                </Button>}
            </Box>}
        </Stack>
    )
}
